import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import React, { useState, useEffect } from "react";

import "swiper/css/bundle";
import "swiper/css/effect-fade";

import Image from "next/image";
import { EffectFade } from "swiper";

import Aos from "aos";
import "aos/dist/aos.css";

function CmsSwiper({ component }) {
  component.options.time = 5000;
  component.options.speed = 1000;

  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  if (component.id == "swiper-1654608625") {
    return (
      <div id={`${component.id ?? ""}`}>
        <Swiper
          modules={[Autoplay, Navigation, Pagination, EffectFade]}
          effect={"fade"}
          spaceBetween={0} 
          autoplay={{ delay: component.options.time }}
          speed={5000}
          autoHeight={false}
          slidesPerView={component.options.slidesPerView}
          pagination={true} 
          loop={component.options.slides.length > 1 ? true : false}
        >
          {component.options.slides.map(function (slide, i) {
            return (
              <SwiperSlide key={`slide${i}`} className={`slide`}>
                <div className={`captionContainer`}>
                  <div
                    className="caption"
                    // data-aos="fade-right"
                    dangerouslySetInnerHTML={{ __html: slide.caption }}
                  />
                  <div
                    className="img-container"
                    // data-aos="fade-left"
                  >
                    {slide.image.url != "" ? (
                      <Image
                        alt=""
                        src={slide.image.url}
                        layout="responsive"
                        width={slide.image.width}
                        height={slide.image.height}
                        priority={1}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    );
  } else {
    return (
      <div id={`${component.id ?? ""}`}>
        {isClient && (
          <Swiper
            modules={[Autoplay, Navigation, Pagination]}
            spaceBetween={0}
            autoplay={{ delay: component.options.time }}
            speed={component.options.speed}
            autoHeight={false}
            // slidesPerView={1}
            navigation={true}
            loop={component.options.slides.length > 1 ? true : false}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              1024: {
                slidesPerView: 2,
              },
              1280: {
                slidesPerView: component.options.slidesPerView,
              },
            }}
          >
            {component.options.slides.map(function (slide, i) {
              return (
                <SwiperSlide key={`slide${i}`} className={``}>
                  <div className="p-6 text-center">
                    <div className="mb-6 flex justify-center">
                      {slide.image.url != "" ? (
                        <Image
                          alt=""
                          src={slide.image.url}
                          layout="intrinsic"
                          width={slide.image.width}
                          height={slide.image.height}
                          priority={1}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <div
                      className="caption"
                      dangerouslySetInnerHTML={{ __html: slide.caption }}
                    />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        )}
      </div>
    );
  }
}

export default CmsSwiper;
